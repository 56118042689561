<template>
  <DialogFormWrapper>
    <template #form>
      <v-row v-if="isContainersMismatch">
        <v-col cols="12">
          <v-checkbox
            v-model="allowContainerMismatch"
            label="Przypisany kontener różni się od zamówionego"
            :disabled="isProcessing"
            hide-details
            class="mt-0"
          />
        </v-col>
      </v-row>
      <v-row v-if="isDebrisTypeMismatch">
        <v-col cols="12">
          <v-checkbox
            v-model="allowDebrisMismatch"
            label="Raportowany kod odpadu różni się od kodu odpadu"
            :disabled="isProcessing"
            hide-details
            class="mt-0"
          />
        </v-col>
      </v-row>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :disabled="isProcessing"
        :loading="isProcessing"
        @click="updateOrderMismatchStatus"
      >
        Zatwierdź
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  data() {
    return {
      allowContainerMismatch: false,
      allowDebrisMismatch: false
    }
  },
  mounted() {
    const { allowContainerMismatch, allowDebrisMismatch } = this.order
    this.allowContainerMismatch = allowContainerMismatch
    this.allowDebrisMismatch = allowDebrisMismatch
  },
  computed: {
    ...mapState({
      isProcessing: state => state.orders.isProcessing,
      order: state => state.layout.dialog.item
    }),
    isContainersMismatch() {
      if (!this.order.containerType || !this.order.container) return false
      return this.order.containerType?.id !== this.order.container?.containerType?.id
    },
    isDebrisTypeMismatch() {
      if (!this.order.reportedDebrisType || !this.order.debrisType) return false
      return this.order.reportedDebrisType?.id !== this.order.debrisType?.id
    }
  },
  methods: {
    ...mapActions({
      editOrder: 'orders/editItem',
      closeDialog: 'layout/closeDialog'
    }),
    updateOrderMismatchStatus() {
      const { allowContainerMismatch, allowDebrisMismatch } = this

      return this.editOrder({
        params: {
          allowContainerMismatch,
          allowDebrisMismatch
        },
        tableName: 'orders',
        id: this.order.id
      }).then(() => {
        this.closeDialog()
      })
    }
  }
}
</script>
